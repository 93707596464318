// Modules
import React from 'react';
import { graphql } from 'gatsby';

// Factories
import WorkshopFactory from '@factories/WorkshopFactory';

// Styles
import styled from '@emotion/styled';
import { colors, spacing, fontWeight, lineHeight, fontSize, screens } from '@styles/theme';

// Components
import { Layout } from '@components/core/layout';
import { Center } from '@components/core/center';
import { Inner } from '@components/core/inner';
import { Typo } from '@components/core/typo';
import { Grid } from '@components/core/grid';

import { WorkshopCard } from '@components/home/workshop-card';
import css from '@emotion/css';
import { Metadata } from '@components/core/metadata';
import { WindowLocation } from '@reach/router';

interface HomePageProps {
    data: any;
    location: WindowLocation;
}

const Description = styled(Typo)`
    margin-top: ${spacing(4)} !important;
    margin-bottom: ${spacing(16)};

    color: ${colors('gray', 500)};
    font-weight: ${fontWeight('normal')};
    font-size: ${fontSize('xl')};
    line-height: ${lineHeight('relaxed')};
`;

const HomePage: React.FC<HomePageProps> = ({ data, location }) => {
    const workshops = WorkshopFactory.batchCreate(data.allMdx.edges);
    if (typeof window === 'undefined') {
        return null;
    }
    return (
        <Layout>
            <Metadata pathname={location.pathname} />
            <Center>
                <Inner>
                    <Typo
                        variant="menu"
                        css={css`
                            position: relative;
                            color: ${colors('secondary')};
                            @media (max-width: ${screens('sm')}) {
                                font-size: 3rem;
                            }
                        `}
                    >
                        Journal de bord
                    </Typo>

                    <Description
                        variant="h2"
                        css={css`
                            @media (max-width: ${screens('sm')}) {
                                font-size: ${fontSize('md')};
                            }
                        `}
                    >
                        Retrouvez les slides, démos et vidéos de mes talks.
                    </Description>
                    <Grid
                        sm={{ columns: 1, columnGap: 0, rowGap: 8 }}
                        md={{ columns: 2, columnGap: 8, rowGap: 8 }}
                        lg={{ columns: 3, columnGap: 8, rowGap: 8 }}
                        css={css`
                            @media (max-width: ${screens('sm')}) {
                                padding-bottom: ${spacing(8)};
                            }
                        `}
                    >
                        {workshops.map(workshop => (
                            <WorkshopCard key={workshop.id} {...workshop} />
                        ))}
                    </Grid>
                </Inner>
            </Center>
        </Layout>
    );
};

export default HomePage;

export const TalksPageQuery = graphql`
    query AllTalks {
        allMdx(
            sort: { fields: frontmatter___index, order: ASC }
            filter: { fields: { contentType: { eq: "workshop" } } }
        ) {
            edges {
                node {
                    fields {
                        id
                        path
                    }
                    frontmatter {
                        index
                        image {
                            childImageSharp {
                                fluid(maxWidth: 800) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        date
                        title
                        description
                        tags
                    }
                }
            }
        }
    }
`;
